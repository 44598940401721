import { Box, Typography } from '@mui/material'
import React from 'react'
import { OfferDocumentsPresentationalProps } from './offerDocumentsTypes'

const DocumentsRenderer = React.lazy(() => import('../DocumentsRenderer'))

const OfferDocumentsTablet = (props: OfferDocumentsPresentationalProps) => {
  const { documentConfigs, slackThreadUrl, isSSR, isGenericOfferPage } = props
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="32px"
      width="100%"
      pb={isGenericOfferPage ? '32px' : '200px'}
    >
      {!isGenericOfferPage && (
        <Typography variant="cta" color="primary.main">
          Document Center
        </Typography>
      )}
      {documentConfigs?.map((documentConfig) => {
        const { title, documents } = documentConfig
        if (documents.length > 0 && !isSSR) {
          return (
            <React.Suspense fallback="">
              <DocumentsRenderer
                title={title}
                documents={documents}
                slackThreadUrl={slackThreadUrl}
              />
            </React.Suspense>
          )
        }
        return null
      })}
    </Box>
  )
}

export default OfferDocumentsTablet
