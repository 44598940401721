import React from 'react'
import ExchangeNavbarDesktop from './ExchangeNavbarDesktop'
import ExchangeNavbarTablet from './ExchangeNavbarTablet'
import ExchangeNavbarMobile from './ExchangeNavbarMobile'
import useExchangeNavbar from './useExchangeNavbar'
import { ExchangeNavbarProps } from './exchangeNavbarTypes'

const ExchangeNavbar = (props: ExchangeNavbarProps) => {
  const presentationalProps = useExchangeNavbar(props)
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <ExchangeNavbarMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <ExchangeNavbarTablet {...presentationalProps} />
  }
  return <ExchangeNavbarDesktop {...presentationalProps} />
}

export default ExchangeNavbar
