import { useMediaQuery, useTheme } from '@mui/material'
import { useFundConfig } from '../FundConfigProvider'
import {
  ExchangeNavbarProps,
  ExchangeNavbarPresentationalProps,
} from './exchangeNavbarTypes'

const useExchangeNavbar: (
  props: ExchangeNavbarProps
) => ExchangeNavbarPresentationalProps = (props: ExchangeNavbarProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const { logo, logoWidthToHeight } = useFundConfig()

  return {
    isMobile,
    isTablet,
    ...props,
    logo,
    logoWidthToHeight,
  }
}

export default useExchangeNavbar
