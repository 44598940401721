import {
  AppBar,
  Toolbar,
  Box,
  Drawer,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import Om721Logo from '../logos/Om721Logo/Om721Logo'
import HamburgerIcon from '../icons/HamburgerIcon/HamburgerIcon'
import CloseMobileIcon from '../icons/CloseMobileIcon/CloseMobileIcon'
import TrackedButton from '../TrackedButton/TrackedButton'
import TrackedIconButton from '../TrackedIconButton/TrackedIconButton'
import TrackedLink from '../TrackedLink/TrackedLink'
import { ExchangeNavbarPresentationalProps } from './exchangeNavbarTypes'

const ExchangeNavbarMobile = (props: ExchangeNavbarPresentationalProps) => {
  const {
    onClick,
    mobileNavItems,
    mobileNavItemsFunction,
    showBackToHome,
    actions,
    offerPage,
    logo,
    logoWidthToHeight,
  } = props
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const setIsOpenCallback = (openState: boolean) => {
    setOpen(openState)
  }

  const Logo = logo

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: offerPage
          ? `${theme.palette.gray1.main}`
          : 'primary.main',
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box width="48px" />
        <TrackedLink
          aria-label="Homepage"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            justifySelf: 'center',
            '&:hover': {
              backgroundColor: 'unset',
            },
          }}
          onClick={onClick}
        >
          <Logo
            color={offerPage ? 'primary' : 'white'}
            width={32 * logoWidthToHeight}
            height={32}
          />
        </TrackedLink>
        <TrackedIconButton aria-label="Menu" onClick={() => setOpen(true)}>
          <HamburgerIcon color={offerPage ? 'primary' : 'white'} />
        </TrackedIconButton>
      </Toolbar>
      <Drawer
        anchor="right"
        open={open}
        disableEnforceFocus
        onClose={(_, reason: string) =>
          reason !== 'backdropClick' && setOpen(false)
        }
        ModalProps={{
          onClose: (_, reason: string) =>
            reason !== 'backdropClick' && setOpen(false),
        }}
        PaperProps={{
          sx: {
            width: '100vw',
            backgroundColor: offerPage ? 'gray1.main' : 'primary.main',
          },
        }}
      >
        <Box
          sx={{
            height: '64px',
            pl: '8px',
            pr: '8px',
            backgroundColor: offerPage ? 'gray1.main' : 'primary.main',
          }}
        >
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box width="48px" />
            <TrackedLink
              sx={{
                justifySelf: 'center',
                '&:hover': {
                  backgroundColor: 'unset',
                },
              }}
              onClick={onClick}
            >
              <Om721Logo
                color={offerPage ? 'primary' : 'white'}
                width={256}
                height={32}
              />
            </TrackedLink>
            <TrackedIconButton onClick={() => setOpen(false)}>
              <CloseMobileIcon color={offerPage ? 'primary' : 'white'} />
            </TrackedIconButton>
          </Toolbar>
        </Box>
        <Box sx={{ p: '36px', pt: 0, height: '100%' }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {actions.map((item) => {
              const { label, onClick: actionOnClick, variant } = item
              return (
                <Grid key={label} item xs={12}>
                  <TrackedButton
                    variant={variant as any}
                    size="small"
                    onClick={actionOnClick}
                    sx={{ width: '100%' }}
                  >
                    {label}
                  </TrackedButton>
                </Grid>
              )
            })}
          </Grid>
          <Box
            display="flex"
            flexDirection="column"
            mt="32px"
            onClick={() =>
              mobileNavItemsFunction ? undefined : setOpen(false)
            }
            height="100%"
          >
            {mobileNavItemsFunction &&
              mobileNavItemsFunction(setIsOpenCallback)}
            {mobileNavItems}
          </Box>
        </Box>
        {showBackToHome && (
          <Box
            position="absolute"
            bottom="40px"
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <Box>
              <TrackedLink to="/" underline="none">
                <Typography variant="cta" color="primary.main">
                  BACK TO HOME
                </Typography>
              </TrackedLink>
            </Box>
          </Box>
        )}
      </Drawer>
    </AppBar>
  )
}

export default ExchangeNavbarMobile
