import { RightArrowIcon } from '@flock/shared-ui'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import PrelimOfferSection from './PrelimOfferSection'
import Tampa from '../../../images/portfolio-hero-opaque.webp'
import OfferPageTablet from '../../../images/investor-preview/offer-page-tablet.webp'

type PrelimPreviewSectionProps = {
  isGeneric?: boolean
}

const PrelimPreviewSection = (props: PrelimPreviewSectionProps) => {
  const { isGeneric } = props

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <PrelimOfferSection
      sectionId="portal-preview"
      nextId={isGeneric ? 'important-resources' : 'next-steps'}
      nextCta={isGeneric ? 'Find out more.' : "What's next?"}
      background={Tampa}
      showMobilePreview={isMobile}
    >
      <Grid item sm={6} md={4} display="flex" justifyContent="center">
        <Box display="flex" alignItems="center">
          <Box display="flex" flexDirection="column" gap="48px">
            <Typography variant="h2" color="primary.main">
              Easily track your shares in the Owners&apos; Portal.
            </Typography>
            {!isMobile && (
              <Box
                display="flex"
                alignItems="center"
                padding="24px"
                gap="8px"
                sx={{
                  background: 'rgba(255, 255, 255, 0.64)',
                  backdropFilter: 'blur(2px)',
                  borderRadius: '24px',
                }}
              >
                <Box>
                  <Typography
                    variant="p1"
                    display="inline"
                    color="primary.main"
                  >
                    View the fund&apos;s performance, see new homes added each
                    month, and manage your account information.
                  </Typography>
                </Box>
                {!isTablet && <RightArrowIcon />}
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      {isMobile ? (
        <Box height="445px" />
      ) : (
        <Grid item sm={6} md={8} display="flex" justifyContent="center">
          <Box
            sx={{
              width: 'fit-content',
              border: 'double 4px transparent',
              borderRadius: '34px',
              marginRight: '-3px',
              backgroundImage:
                'linear-gradient(white, white), radial-gradient(circle at top left, rgba(46, 47, 51, 1),rgba(151, 152, 157, 1))',
              backgroundOrigin: 'border-box',
              backgroundClip: 'padding-box, border-box',
            }}
          >
            <Box
              width={{
                sm: '520px',
                md: '686px',
              }}
              height={{
                sm: '390px',
                md: '514px',
              }}
            >
              <Box
                width={{
                  sm: '520px',
                  md: '686px',
                }}
                height={{
                  sm: '390px',
                  md: '514px',
                }}
                position="relative"
                sx={{
                  overflowX: 'hidden',
                  overflowY: 'scroll',
                  backgroundColor: 'lightBackground.main',
                  border: '20px solid black',
                  borderRadius: '30px',
                }}
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  sx={{
                    backgroundColor: 'lavender.main',
                  }}
                >
                  <Typography
                    variant="c1"
                    color="white.main"
                    sx={{ fontSize: '12px' }}
                  >
                    THIS IS A PLACEHOLDER ACCOUNT VIEW FOR DEMO PURPOSES.
                  </Typography>
                </Box>
                <Box
                  width={{
                    sm: '520px',
                    md: '686px',
                  }}
                  component="img"
                  src={OfferPageTablet}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      )}
    </PrelimOfferSection>
  )
}

export default PrelimPreviewSection

PrelimPreviewSection.defaultProps = {
  isGeneric: false,
}
