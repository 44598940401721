import { Core_TransactionType } from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  BathroomIcon,
  BedroomIcon,
  HouseOutlinedIcon,
  MoneyOutlinedIcon,
  SparkleIcon,
  SqftIcon,
} from '@flock/shared-ui'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { numberWithCommas } from '../../utils'
import HomeDetail from '../HomeDetail'
import PortfolioHomeDetails from '../PortfolioHomeDetails'
import { OfferPortfolioPresentationalProps } from './offerPortfolioTypes'

const OfferPortfolioDesktop = (props: OfferPortfolioPresentationalProps) => {
  const {
    streetViewUrl,
    street,
    bedrooms,
    bathrooms,
    sqft,
    homeType,
    homeCondition,
    rent,
    transactionType,
    leadName,
    portfolioName,
    portfolioDoorCount,
    portfolioAssetCount,
    portfolioCityState,
    portfolioSFRDoors,
    portfolioMultifamilyDoors,
    capex,
    offerPrice,
  } = props

  const HomeDetailComponents = (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Typography variant="cta">{street}</Typography>
      <Box display="flex" flexDirection="row" gap="8px">
        {bedrooms ? (
          <HomeDetail icon={BedroomIcon} text={`${bedrooms} bed`} />
        ) : null}
        {bathrooms ? (
          <HomeDetail icon={BathroomIcon} text={`${bathrooms} bath`} />
        ) : null}
      </Box>
      {sqft ? (
        <HomeDetail icon={SqftIcon} text={`${numberWithCommas(sqft)} sq ft`} />
      ) : null}
      {homeType && <HomeDetail icon={HouseOutlinedIcon} text={homeType} />}
      {homeCondition && <HomeDetail icon={SparkleIcon} text={homeCondition} />}

      {rent ? (
        <HomeDetail
          icon={MoneyOutlinedIcon}
          text={`$${numberWithCommas(rent)}/month rent`}
        />
      ) : null}
    </Box>
  )

  const TwoColumnHomeDetailComponents = (
    <Box
      display="flex"
      flexDirection="row"
      gap="32px"
      justifyContent="space-between"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="96px"
        width="50%"
      >
        {bedrooms && <HomeDetail icon={BedroomIcon} text={`${bedrooms} bed`} />}
        {bathrooms && (
          <HomeDetail icon={BathroomIcon} text={`${bathrooms} bath`} />
        )}
        {sqft && (
          <HomeDetail
            icon={SqftIcon}
            text={`${numberWithCommas(sqft)} sq ft`}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="96px"
        width="50%"
      >
        {homeType && <HomeDetail icon={HouseOutlinedIcon} text={homeType} />}
        {homeCondition && (
          <HomeDetail icon={SparkleIcon} text={homeCondition} />
        )}
        <Box height="24px" />
      </Box>
    </Box>
  )

  return (
    <>
      {transactionType === Core_TransactionType.TransactionTypePortfolio_721 ? (
        <PortfolioHomeDetails
          leadName={leadName}
          portfolioName={portfolioName}
          portfolioDoorCount={portfolioDoorCount}
          portfolioAssetCount={portfolioAssetCount}
          portfolioCityState={portfolioCityState}
          portfolioSFRDoors={portfolioSFRDoors}
          portfolioMultifamilyDoors={portfolioMultifamilyDoors}
          capex={capex}
          offerPrice={offerPrice}
        />
      ) : (
        <Box display="flex" flexDirection="row">
          {streetViewUrl ? (
            <>
              <Box
                component="img"
                src={streetViewUrl}
                alt={`Picture of ${street}`}
                width="50%"
                height="248px"
                sx={{
                  boxShadow: 8,
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                }}
              />

              <Box
                width="50%"
                display="flex"
                flexDirection="column"
                padding="16px"
                sx={{
                  boxShadow: 8,
                  borderTopRightRadius: '8px',
                  borderBottomRightRadius: '8px',
                  backgroundColor: 'lightBackground.main',
                }}
              >
                <>{HomeDetailComponents}</>
              </Box>
            </>
          ) : (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              padding="24px"
              sx={{
                boxShadow: 8,
                borderRadius: '8px',
                backgroundColor: 'lightBackground.main',
              }}
            >
              <Typography
                sx={{ paddingBottom: '16px', textAlign: 'center' }}
                variant="cta"
              >
                {street}
              </Typography>
              <>{TwoColumnHomeDetailComponents}</>
            </Box>
          )}
        </Box>
      )}
    </>
  )
}

export default OfferPortfolioDesktop
