import { useMediaQuery, useTheme } from '@mui/material'
import { CALENDLY_LANDING_URL } from '../../../constants'
import { useCalendly } from '../../utils'
import { FooterProps, FooterPresentationalProps } from './footerTypes'

const useFooter: (props: FooterProps) => FooterPresentationalProps = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const { onOpenCalendly, ...otherCalendlyProps } = useCalendly({
    utmMedium: 'footer',
    scheduleUrl: CALENDLY_LANDING_URL as string,
  })

  return {
    isMobile,
    isTablet,
    onOpenCalendly,
    otherCalendlyProps,
  }
}

export default useFooter
