import { Om721Logo, OmSidebarWithIcons } from '@flock/shared-ui'
import { Box, Grid, useTheme } from '@mui/material'
import React from 'react'
import SectionLayout from '../../SharedComponents/SectionLayout'
import { navigateToHomepage } from '../../utils'
import FloatingMessage from '../FloatingMessage'
import { OfferPageSidebarProps } from './offerPageSidebarTypes'

const OfferPageSidebarDesktop = (props: OfferPageSidebarProps) => {
  const {
    sidebarRef,
    sidebarHeight,
    leadStatus,
    sidebarItems,
    onChangeSidebar,
    selectedSection,
  } = props

  const theme = useTheme()

  return (
    <Box
      position="sticky"
      top="40px"
      width="100%"
      sx={{ pointerEvents: 'none' }}
    >
      <Box ref={sidebarRef} marginBottom={`${sidebarHeight * -1}px`}>
        <SectionLayout
          name="sidebar"
          sidePadding="0px"
          columns={12}
          backgroundColor="#ffffff00"
        >
          <Grid item xs={3} sx={{ pointerEvents: 'all' }}>
            <Box
              position="absolute"
              onClick={navigateToHomepage}
              top="28px"
              padding="16px"
              sx={{ '&:hover': { cursor: 'pointer' } }}
            >
              <Om721Logo color="logo" width="214px" />
            </Box>
            <Box marginTop="20px" />

            <OmSidebarWithIcons
              items={sidebarItems}
              onChange={onChangeSidebar}
              selected={selectedSection}
              iconColor={theme.palette.sidebarHighlight.main}
              textColor={theme.palette.sidebarHighlight.main}
              selectedColor={theme.palette.sidebarHighlight.main}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={3} sx={{ pointerEvents: 'all' }}>
            <Box pt="25px">
              <FloatingMessage leadStatus={leadStatus} />
            </Box>
          </Grid>
        </SectionLayout>
      </Box>
    </Box>
  )
}

export default OfferPageSidebarDesktop
