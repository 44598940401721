import React from 'react'
import { styled, Button, Typography, Box } from '@mui/material'
import { useFundConfig } from '@flock/shared-ui'

import { useCalendly } from '../utils'
import ScheduleCall from '../SharedComponents/ScheduleCall'

const HeaderText = styled(Typography)({
  marginBottom: '1rem',
})

const ButtonContainer = styled('div')({
  marginTop: '1.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  width: '15rem',
})

type ErrorCardProps = {
  text: string
}

const ErrorCard = (props: ErrorCardProps) => {
  const { text: errorText } = props
  const { calendlyLandingURL, supportEmail } = useFundConfig()

  const { onOpenCalendly, ...otherCalendlyProps } = useCalendly({
    utmMedium: 'error-card',
    scheduleUrl: calendlyLandingURL as string,
  })

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <HeaderText variant="h2" align="center">
          Oops, an error has occurred.
        </HeaderText>
        <Typography variant="body1" align="center">
          {errorText}
        </Typography>
        <ScheduleCall {...otherCalendlyProps} />
        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={onOpenCalendly}
            sx={{ marginRight: '1rem' }}
          >
            Call
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.open(`mailto:${supportEmail}`)
            }}
          >
            Email
          </Button>
        </ButtonContainer>
      </Box>
    </Box>
  )
}

export default ErrorCard
