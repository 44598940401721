import { Om721Logo, TrackedButton, TrackedLink } from '@flock/shared-ui'
import { Box, styled, Typography } from '@mui/material'
import React from 'react'
import {
  INVESTOR_URL,
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  SUPPORT_PRETTY_PHONE,
} from '../../../constants'
import {
  ABOUT_US_PATH,
  CALCULATOR_PATH,
  FAQ_PATH,
  HOW_721_WORKS_PATH,
  HOW_TO_SIGN_UP_PATH,
  ONBOARDING_PATH,
  PORTFOLIO_PATH,
  PRIVACY_POLICY_PATH,
  TERMS_OF_USE_PATH,
} from '../../../routeConstants'
import ScheduleCall from '../ScheduleCall'
import { FooterPresentationalProps } from './footerTypes'

const FooterLink = styled(TrackedLink)(({ theme }) => ({
  color: theme.palette.white.main,
}))

const FooterDesktop = (props: FooterPresentationalProps) => {
  const { onOpenCalendly, otherCalendlyProps } = props
  return (
    <>
      <ScheduleCall {...otherCalendlyProps} />

      <Box
        display="flex"
        justifyContent="space-between"
        gap="80px"
        sx={{ backgroundColor: 'darkBackground.main' }}
      >
        <Box display="flex" flexDirection="column" gap="24px">
          <Om721Logo color="white" />
          <TrackedButton to={ONBOARDING_PATH} size="small" sx={{ py: '5px' }}>
            Request an offer
          </TrackedButton>
          <Box width="272px">
            <TrackedLink
              display="inline"
              variant="p1"
              sx={{
                color: 'white.main',
              }}
              onClick={() => {
                window.open(`tel:${SUPPORT_PHONE}`)
              }}
            >
              Call {SUPPORT_PRETTY_PHONE},
            </TrackedLink>
            <br />
            <TrackedLink
              display="inline"
              onClick={onOpenCalendly}
              variant="p1"
              sx={{
                color: 'white.main',
              }}
            >
              Schedule a call,{' '}
            </TrackedLink>
            <Typography display="inline" color="#FFFFFF50">
              or email{' '}
            </Typography>
            <TrackedLink
              display="inline"
              sx={{
                color: 'white.main',
              }}
              to={`mailto:${SUPPORT_EMAIL}`}
              variant="p1"
            >
              721exchange@overmoon.com
            </TrackedLink>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" gap="72px">
          <Box display="flex" flexDirection="column" gap="16px">
            <Typography variant="p1" color="#FFFFFF50">
              Learn
            </Typography>
            <FooterLink to={HOW_721_WORKS_PATH} variant="p2">
              How 721 works
            </FooterLink>
            <FooterLink to={HOW_TO_SIGN_UP_PATH} variant="p2">
              How to sign up
            </FooterLink>
            <FooterLink to={CALCULATOR_PATH} variant="p2">
              Calculator
            </FooterLink>
            <FooterLink to={FAQ_PATH} variant="p2">
              FAQ
            </FooterLink>
          </Box>
          <Box display="flex" flexDirection="column" gap="16px">
            <Typography variant="p1" color="#FFFFFF50">
              About
            </Typography>
            <FooterLink to={ABOUT_US_PATH} variant="p2">
              About Overmoon
            </FooterLink>
          </Box>
          <Box display="flex" flexDirection="column" gap="16px">
            <Typography variant="p1" color="#FFFFFF50">
              Investments
            </Typography>
            <FooterLink to={PORTFOLIO_PATH} variant="p2">
              Portfolio
            </FooterLink>
          </Box>
          <Box display="flex" flexDirection="column" gap="16px">
            <Typography variant="p2" color="#FFFFFF50">
              Account
            </Typography>
            <FooterLink to={ONBOARDING_PATH} variant="p2">
              Request Offer
            </FooterLink>
            <FooterLink to={INVESTOR_URL} variant="p2">
              Sign In
            </FooterLink>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" paddingTop="24px">
        <Box display="flex" gap="32px">
          <FooterLink to={TERMS_OF_USE_PATH} variant="p2">
            Terms of Use
          </FooterLink>
          <FooterLink to={PRIVACY_POLICY_PATH}>Privacy</FooterLink>
        </Box>
        <Typography variant="p2" color="#FFFFFF50">
          Copyright 2023 Overmoon 721 Fund
        </Typography>
      </Box>
    </>
  )
}

export default FooterDesktop
