import React from 'react'
import {
  Box,
  Typography,
  SvgIconProps,
  useTheme,
  useMediaQuery,
} from '@mui/material'

type HomeDetailProps = {
  icon: React.FC<SvgIconProps>
  text: string
}

const HomeDetail = (props: HomeDetailProps) => {
  const { icon: Icon } = props
  let { text } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  if (text === 'Single-family detached home') {
    text = 'Single-family home'
    if (isMobile) {
      text = 'Singlefamily'
    }
  }
  return (
    <>
      {isMobile ? (
        <Box display="flex" gap="8px" alignItems="center">
          <Icon height="20px" width="20px" />
          <Typography variant="p3" color="gray8.main">
            {text}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'inline-block',
          }}
        >
          <Box display="flex" gap="10px" alignItems="center">
            <Icon height="20px" width="20px" />
            <Typography variant="l1" color="primary.main">
              {text}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  )
}

export default HomeDetail
