import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import PrelimOfferSection from '../PrelimOfferSection'

const GenericPrelimHeroDesktop = (props: GenericPrelimHeroProps) => {
  const { fullName, leadNameOverride } = props

  const firstName = fullName.split(' ')[0]
  const displayName = leadNameOverride || firstName

  return (
    <PrelimOfferSection sectionId="offer" isGenericOfferPage>
      <Grid item sm={6} md={12} order={{ md: 3, sm: 1, xs: 1 }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={{
            xs: '16px',
            sm: '32px',
            md: '32px',
          }}
          sx={{ paddingBottom: '24px' }}
        >
          <Typography variant="h2" color="primary.main">
            Welcome to Flock
            {!displayName ? '.' : `, ${displayName}.`}{' '}
          </Typography>
        </Box>
      </Grid>
    </PrelimOfferSection>
  )
}

export default GenericPrelimHeroDesktop
