import React, { useState } from 'react'
import {
  CloseMobileIcon,
  HamburgerIcon,
  Om721Logo,
  TrackedIconButton,
  TrackedLink,
} from '@flock/shared-ui'
import { Box, Drawer, Typography, useMediaQuery, useTheme } from '@mui/material'
import { navigateToHomepage } from '../../utils'

type NavLinkProps = {
  name: string
  id: string
  currentSection: string
  onClick: () => void
}

const NavLink = (props: NavLinkProps) => {
  const { id, name, onClick, currentSection } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleNavigate = () => {
    onClick()
    const el = document.getElementById(id)
    const yOffset = -32
    const y = el?.getBoundingClientRect().top! + window.pageYOffset + yOffset

    window.scrollTo({
      behavior: 'smooth',
      top: y,
    })

    if (isMobile) {
      window.location.href = `#${id}`
    }
  }

  return (
    <TrackedLink
      variant="h2"
      onClick={() => handleNavigate()}
      sx={{
        textDecoration: id === currentSection ? 'underline' : 'unset',
        color: 'logo.main',
      }}
    >
      {name}
    </TrackedLink>
  )
}

type PrelimOfferNavProps = {
  currentSection: string
  isGeneric?: boolean
}

const PrelimOfferNav = (props: PrelimOfferNavProps) => {
  const { currentSection, isGeneric } = props
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pl={{
          sm: '96px',
          md: '96px',
        }}
        pr={{
          sm: '96px',
          md: '96px',
        }}
        sx={{
          backgroundColor: `${theme.palette.gray1.main}95`,
          backdropFilter: 'blur(4px)',
        }}
      >
        {isMobile && <Box width="48px" />}
        <Box
          onClick={navigateToHomepage}
          padding="16px"
          paddingLeft="8px"
          paddingRight="8px"
        >
          <Typography
            variant="h4"
            fontWeight={500}
            color="logo.main"
            display="flex"
            alignItems="center"
            sx={{ '&:hover': { cursor: 'pointer' } }}
          >
            {isMobile ? (
              <Om721Logo color="logo" width="213px" height="25px" />
            ) : (
              <Om721Logo color="logo" />
            )}
          </Typography>
        </Box>
        <Box>
          <TrackedIconButton aria-label="Menu" onClick={() => setOpen(true)}>
            <HamburgerIcon />
          </TrackedIconButton>
        </Box>
      </Box>
      <Drawer
        anchor="right"
        open={open}
        disableEnforceFocus
        disableRestoreFocus
        onClose={() => {
          setOpen(false)
        }}
        ModalProps={{
          onClose: () => {
            setOpen(false)
          },
        }}
        PaperProps={{
          sx: { backgroundColor: 'green1.main' },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          width={{
            xs: '100vw',
            sm: 'inherit',
          }}
          alignItems={{
            xs: 'center',
            sm: 'flex-end',
          }}
          padding={{
            xs: '0px',
            sm: '64px 112px 80px 96px',
            md: '64px 112px 80px 96px',
          }}
          gap="60px"
        >
          <Box display="flex" justifyContent="flex-end" width="100%">
            <TrackedIconButton onClick={() => setOpen(false)}>
              <CloseMobileIcon width="48px" height="48px" />
            </TrackedIconButton>
          </Box>
          <Box sx={{ p: '48px', pt: 0, height: '100%' }}>
            <Box display="flex" flexDirection="column" gap="32px" height="100%">
              {!isGeneric && (
                <NavLink
                  name="Offer."
                  id="offer"
                  currentSection={currentSection}
                  onClick={() => setOpen(false)}
                />
              )}
              <NavLink
                name="Portal."
                id="portal-preview"
                currentSection={currentSection}
                onClick={() => setOpen(false)}
              />
              <NavLink
                name={isGeneric ? 'Important resources.' : 'Next steps.'}
                id={isGeneric ? 'important-resources' : 'next-steps'}
                currentSection={currentSection}
                onClick={() => setOpen(false)}
              />
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  )
}

export default PrelimOfferNav

PrelimOfferNav.defaultProps = {
  isGeneric: false,
}
