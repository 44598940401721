import { TrackedButton, TrackedLink, useFundConfig } from '@flock/shared-ui'
import {
  Box,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useContext } from 'react'
import { FAQ_PATH } from '../../../routeConstants'
import { useCalendly } from '../../utils'
import ScheduleCall from '../../SharedComponents/ScheduleCall'
import PrelimOfferSection from './PrelimOfferSection'
import OfferPageContext from '../OfferPageContext'

const OVERVIEW_DECK_URL =
  'https://overmoon-static-documents.s3.us-east-2.amazonaws.com/development/Overmoon+Overview+Deck'

const StepCard = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '16px',
  backgroundColor: '#fff',
  borderRadius: '24px',
})

const PrelimNextStepsSection = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const context = useContext(OfferPageContext)
  const { onOpenCalendly, ...otherCalendlyProps } = useCalendly({
    utmMedium: 'prelim-offer-page',
    scheduleUrl: context.calendlyLink || '',
  })

  const { fundName } = useFundConfig()

  const salesAssigneeFirstName =
    context?.salesAssigneeName?.split(' ')[0] || 'Jacob'

  return (
    <PrelimOfferSection sectionId="next-steps">
      <Grid item xs={4} sm={6} md={12}>
        <Typography variant="h2" alignSelf="flex-start" color="primary.main">
          We&apos;re excited to accompany you on the next step of your ownership
          journey.
        </Typography>
      </Grid>
      <Grid item xs={4} sm={6} md={4}>
        <StepCard sx={{ gap: '8px' }}>
          <Typography variant="h4" color="gray5.main">
            Tasks you&apos;ve completed.
          </Typography>
          <Typography variant="p3" color="gray5.main">
            Provide property details.
          </Typography>
        </StepCard>
      </Grid>
      <Grid item xs={4} sm={6} md={4}>
        <StepCard>
          <Typography variant="h4" color="primary.main">
            Current action items.
          </Typography>
          <Box display="flex" flexDirection="column" gap="4px">
            <Typography variant="p1" color="gray8.main">
              Get an offer.
            </Typography>
            <Box display="flex" paddingLeft="24px" gap="8px">
              <Typography variant="p2" color="gray8.main">
                We&apos;re working on your initial offer - you&apos;ll receive
                it in your email within 24 hours.
              </Typography>
            </Box>
          </Box>
        </StepCard>
      </Grid>
      <Grid item xs={4} sm={6} md={4}>
        <StepCard>
          <Typography variant="h4" color="gray8.main">
            In the meantime...
          </Typography>
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="p1" color="gray8.main">
              Have any questions?
            </Typography>
            <TrackedLink
              onClick={() => window.open(FAQ_PATH, '_blank')}
              variant="p2"
            >
              Explore our Frequently Asked Questions.
            </TrackedLink>
          </Box>
          <ScheduleCall {...otherCalendlyProps} user={context.user} />
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="p1" color="gray8.main">
              Have questions we haven&apos;t covered?
            </Typography>
            <TrackedLink variant="p2" onClick={onOpenCalendly}>
              {`Schedule a call with ${salesAssigneeFirstName}, your investment advisor.`}
            </TrackedLink>
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="p1" color="gray8.main">
              Need a short overview?
            </Typography>
            <TrackedLink
              variant="p2"
              onClick={() => window.open(OVERVIEW_DECK_URL, '_blank')}
            >
              Download our overview deck.
            </TrackedLink>
          </Box>
        </StepCard>
      </Grid>
      <Grid
        item
        xs={4}
        sm={6}
        md={12}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="24px"
        marginTop={{
          xs: '24px',
          sm: '0px',
        }}
      >
        <Typography
          variant="h3"
          color="primary.main"
          textAlign={isMobile ? 'center' : 'left'}
        >
          Discover more about whether the {fundName} is the right fit for you.{' '}
        </Typography>
        <Box display="flex" gap="23px" alignItems="center">
          <Box
            width={{
              xs: '248px',
              sm: '500px',
              md: '500px',
            }}
          >
            <TrackedButton
              size="large"
              variant="tertiary"
              onClick={onOpenCalendly}
            >
              {isMobile
                ? `Schedule a call with ${salesAssigneeFirstName}`
                : `Schedule a walkthrough call with us`}
            </TrackedButton>
          </Box>
        </Box>
      </Grid>
    </PrelimOfferSection>
  )
}

export default PrelimNextStepsSection
