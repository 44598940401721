import { RightArrowIcon, TrackedLink } from '@flock/shared-ui'
import { Box, Typography } from '@mui/material'
import React from 'react'
import ScheduleCall from '../../SharedComponents/ScheduleCall'
import OfferPagePaper from '../OfferPagePaper'
import { OfferTasksPresentationalProps } from './offerTasksTypes'

const OfferTaskTablet = (props: OfferTasksPresentationalProps) => {
  const { otherCalendlyProps, context, onClick, actionText, description } =
    props

  return (
    <Box display="flex" flexDirection="column" gap="32px" width="100%">
      <Typography variant="cta" color="primary.main">
        See your next steps.
      </Typography>
      <OfferPagePaper>
        <Box display="flex" flexDirection="column" gap="24px">
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="h4" color="primary.main">
              Current action items.
            </Typography>
            {onClick ? (
              <>
                <ScheduleCall {...otherCalendlyProps} user={context.user} />
                <TrackedLink variant="p1" color="green4.main" onClick={onClick}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap="8px"
                    alignItems="center"
                  >
                    {actionText}
                    <RightArrowIcon height="24px" width="24px" color="green4" />
                  </Box>
                </TrackedLink>
              </>
            ) : (
              <>
                <Typography variant="p1" color="gray5.main">
                  {actionText}
                </Typography>
                {description && (
                  <Typography variant="p2" color="gray5.main">
                    {description}
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Box>
      </OfferPagePaper>
    </Box>
  )
}

export default OfferTaskTablet
