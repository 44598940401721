import React from 'react'
import FooterDesktop from './FooterDesktop'
import FooterTablet from './FooterTablet'
import FooterMobile from './FooterMobile'
import useFooter from './useFooter'
import { FooterProps } from './footerTypes'

const Footer = (props: FooterProps) => {
  const presentationalProps = useFooter(props)
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <FooterMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <FooterTablet {...presentationalProps} />
  }
  return <FooterDesktop {...presentationalProps} />
}

export default Footer
