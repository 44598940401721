import { TrackedButton, TrackedLink, useFundConfig } from '@flock/shared-ui'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import {
  Core_LeadStage,
  Core_TransactionType,
  Core_ValuationType,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import OfferPagePaper from './OfferPagePaper'
import { LeadStatus } from './offerPageTypes'
import { hideExpirationLeadStages, useCalendly } from '../utils'
import OfferPageContext from './OfferPageContext'
import ScheduleCall from '../SharedComponents/ScheduleCall'

type HasOfferDetailsDisplayProps = {
  street: string
  cityStateZipcode: string
  finalOffer: number
  offerLow: number
  offerHigh: number
  saamValuationType: Core_ValuationType | undefined
  transactionType: Core_TransactionType
}

const getOfferDescription = (
  finalOffer: number,
  saamValuationType: Core_ValuationType | undefined
) => {
  let offerDescription = 'offer' // should never really happen for now
  if (!finalOffer) {
    offerDescription = 'initial offer range'
  } else if (
    saamValuationType === Core_ValuationType.ValuationTypePreliminary ||
    saamValuationType === Core_ValuationType.ValuationTypeInitial
  ) {
    offerDescription = 'initial offer'
  } else if (saamValuationType === Core_ValuationType.ValuationTypeFinal) {
    offerDescription = 'final offer'
  }
  return offerDescription
}

// behavior: if it's a prelim valuation, it will always be an offer range. The only time a single number is shown is if it's a final offer

const HasOfferDetailsDisplay = (props: HasOfferDetailsDisplayProps) => {
  const {
    street,
    cityStateZipcode,
    finalOffer,
    offerLow,
    offerHigh,
    saamValuationType,
    transactionType,
  } = props

  const { supportPhone, prettyPhone } = useFundConfig()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const offerDescription = getOfferDescription(finalOffer, saamValuationType)

  const context = useContext(OfferPageContext)
  const { onOpenCalendly, ...otherCalendlyProps } = useCalendly({
    utmMedium: 'floating-cta-offer-page',
    scheduleUrl: context.calendlyLink || '',
  })
  return (
    <OfferPagePaper backgroundColor="white.main">
      <Box display="flex" flexDirection="column" gap="8px" textAlign="center">
        {finalOffer !== 0 && (
          <>
            <Box>
              <Typography variant="c1" fontWeight="400" fontSize="14px">
                Your {offerDescription} for
              </Typography>
              {transactionType ===
              Core_TransactionType.TransactionTypePortfolio_721 ? (
                <Typography variant="c1" fontSize="14px" fontWeight="400">
                  {' '}
                  your portfolio{' '}
                </Typography>
              ) : (
                <>
                  <Typography variant="c1" fontSize="14px" fontWeight="400">
                    {' '}
                    {street}
                    {cityStateZipcode.trim() ? ',' : ''}{' '}
                  </Typography>
                  <Typography variant="c1" fontSize="14px" fontWeight="400">
                    {cityStateZipcode}{' '}
                  </Typography>
                </>
              )}
              <span style={{ whiteSpace: 'nowrap' }}>
                <Typography variant="c1" fontWeight="400" fontSize="14px">
                  is
                </Typography>
              </span>
            </Box>
            <Typography variant="h1">
              ${finalOffer?.toLocaleString()}
            </Typography>
          </>
        )}
        {/* New Mailer goes out with offer range - we don't want to show a different offer range if they go through flow.
        uncomment at a later time. */}
        {offerLow !== 0 && offerHigh !== 0 && !finalOffer && (
          <>
            <Box>
              <Typography variant="c1" fontWeight="400" fontSize="14px">
                Your {offerDescription} for
              </Typography>
              <Typography variant="c1" fontSize="14px">
                {' '}
                {street}
                {cityStateZipcode.trim() ? ',' : ''}{' '}
              </Typography>
              <span style={{ whiteSpace: 'nowrap' }}>
                <Typography variant="c1" fontSize="14px">
                  {cityStateZipcode}{' '}
                </Typography>
                <Typography variant="c1" fontWeight="400" fontSize="14px">
                  is
                </Typography>
              </span>
            </Box>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Typography variant="h2" fontSize="2.5rem">
                ${offerLow?.toLocaleString()}
              </Typography>
              <Typography variant="h2" fontSize="2.5rem">
                -
              </Typography>
              <Typography variant="h2" fontSize="2.5rem">
                ${offerHigh?.toLocaleString()}
              </Typography>
            </Box>
            <Box
              width="100%"
              height="8px"
              sx={{
                backgroundImage:
                  'linear-gradient(90deg, #004021 0.11%, rgba(0, 64, 33, 0.2) 50.23%, #004021 100%)',
              }}
            />
          </>
        )}
      </Box>
      <Box
        display="flex"
        alignSelf="center"
        sx={{
          width: isMobile ? '272px' : '496px',
          borderTop: '1px solid grey',
          my: '20px',
        }}
      />

      <Box display="flex" flexDirection="column" gap="16px">
        <Typography display="flex" alignSelf="center">
          Next Step
        </Typography>
        <ScheduleCall {...otherCalendlyProps} user={context.user} />
        <TrackedButton size="small" variant="tertiary" onClick={onOpenCalendly}>
          <Typography variant="c1" fontSize="18px">
            Schedule a call
          </Typography>
        </TrackedButton>
        <Box>
          <Typography variant="p2" display="inline">
            Call us at{' '}
          </Typography>
          <TrackedLink
            display="inline"
            variant="p2"
            sx={{
              color: 'logo.main',
            }}
            onClick={() => {
              window.open(`tel:${supportPhone}`)
            }}
          >
            {prettyPhone}
          </TrackedLink>{' '}
          <Typography variant="p2" display="inline">
            or schedule a call to discuss your offer with us and schedule an
            inspection.
          </Typography>
        </Box>
      </Box>
    </OfferPagePaper>
  )
}

type NoOfferDetailsDisplayProps = {
  street: string
}

const NoOfferDetailsDisplay = (props: NoOfferDetailsDisplayProps) => {
  const { street } = props
  return (
    <OfferPagePaper backgroundColor="white.main">
      <Box display="flex" flexDirection="column" gap="8px" textAlign="center">
        <Typography variant="c1" fontWeight="500px" fontSize="1rem">
          We&#39;re generating your initial offer for
        </Typography>

        <Typography variant="h2">{street}</Typography>
        <Typography variant="c1" fontWeight="500px">
          We&#39;ll email you your initial offer within 24 hours, guaranteed.
        </Typography>
      </Box>
    </OfferPagePaper>
  )
}

type ExpiredOfferDetailsDisplayProps = {
  street: string
  cityStateZipcode: string
  expiryDateDisplay: string
  finalOffer: number
  offerLow: number
  offerHigh: number
  saamValuationType: Core_ValuationType | undefined
}

const ExpiredOfferDetailsDisplay = (props: ExpiredOfferDetailsDisplayProps) => {
  const {
    street,
    cityStateZipcode,
    expiryDateDisplay,
    finalOffer,
    offerLow,
    offerHigh,
    saamValuationType,
  } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const offerDescription = getOfferDescription(finalOffer, saamValuationType)

  return (
    <OfferPagePaper backgroundColor="white.main">
      <Box display="flex" flexDirection="column" gap="8px" textAlign="center">
        <Box>
          <Typography variant="c1" fontWeight="400px" fontSize="14px">
            Your {offerDescription} for
          </Typography>
          <Typography variant="c1" fontWeight="500px" fontSize="14px">
            {' '}
            {street}
            {cityStateZipcode.trim() ? ',' : ''}{' '}
          </Typography>
          <span style={{ whiteSpace: 'nowrap' }}>
            <Typography variant="c1" fontSize="14px">
              {cityStateZipcode}{' '}
            </Typography>
            <Typography variant="c1" fontWeight="400" fontSize="14px">
              was
            </Typography>
          </span>
        </Box>
        {finalOffer ? (
          <Typography variant="h2">
            {' '}
            ${finalOffer?.toLocaleString()}{' '}
          </Typography>
        ) : (
          <>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Typography variant="h2" fontSize="2.5rem">
                ${offerLow?.toLocaleString()}
              </Typography>
              <Typography variant="h2" fontSize="2.5rem">
                -
              </Typography>
              <Typography variant="h2" fontSize="2.5rem">
                ${offerHigh?.toLocaleString()}
              </Typography>
            </Box>
            <Box
              width="100%"
              height="8px"
              sx={{
                backgroundImage:
                  'linear-gradient(90deg, #891A1A 0.11%, rgba(137, 26, 26, 0.2) 50.23%, #891A1A 100%)',
              }}
            />
          </>
        )}

        <Typography variant="c1" color="errorRed.main" fontWeight="500px">
          This offer expired on {expiryDateDisplay}.
        </Typography>
      </Box>
    </OfferPagePaper>
  )
}

type OfferDetailsProps = {
  name: string
  street: string
  zipcode: string
  cityStateZipcode: string
  hasCityStateZipcodeOverride: boolean
  expiryDate: Date
  latitude: number
  longitude: number
  hideValuation: boolean
  finalOffer: number
  prelimOffer: number
  offerLow: number
  offerHigh: number
  propertyCondition: string
  leadStage: Core_LeadStage
  isOverriddenAddress: boolean
  saamValuationType: Core_ValuationType | undefined
  leadStatus: LeadStatus
  inspectionDate?: Date
  leadUuid: string
  preinspectionSurveyCompleted: boolean
  transactionType: string
  scheduleInspectionRequested: boolean
  updateScheduleInspectionRequested: (requested: boolean) => void
  contributionAgreementRequested: boolean
  updateContributionAgreementRequested: (requested: boolean) => void
}

const OfferDetails = (props: OfferDetailsProps) => {
  const {
    name,
    street,
    cityStateZipcode,
    expiryDate,
    finalOffer,
    offerLow,
    offerHigh,
    hideValuation,
    leadStage,
    saamValuationType,
  } = props

  const { companyName } = useFundConfig()

  let isExpired = new Date().getTime() > expiryDate.getTime()
  const isValidExpiration = expiryDate.getFullYear() > 2000
  const hideExpiry = hideExpirationLeadStages.includes(leadStage)
  if (!isValidExpiration || hideExpiry) {
    isExpired = false
  }
  const expiryDateDisplay = expiryDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="32px"
      pt="24px"
      width="100%"
    >
      {name ? (
        <Typography variant="h3">Hi, {name}!</Typography>
      ) : (
        <Typography variant="h3">Hi, welcome to {companyName}!</Typography>
      )}

      {isExpired && (
        <ExpiredOfferDetailsDisplay
          street={street}
          cityStateZipcode={cityStateZipcode}
          expiryDateDisplay={expiryDateDisplay}
          finalOffer={finalOffer}
          offerLow={offerLow}
          offerHigh={offerHigh}
          saamValuationType={saamValuationType}
        />
      )}
      {!hideValuation && !isExpired && <HasOfferDetailsDisplay {...props} />}
      {hideValuation && !isExpired && (
        <NoOfferDetailsDisplay street={street || ''} />
      )}
    </Box>
  )
}

export default OfferDetails

OfferDetails.defaultProps = {
  inspectionDate: undefined,
}
